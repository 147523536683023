
@font-face {
	font-family: 'Polo22Klett';
	font-weight: normal;
	font-style: normal;
	src: local(Polo22Klett),
		url('#{$base-font-path}Polo22Klett-Regular.woff2') format('woff2'),
		url('#{$base-font-path}Polo22Klett-Regular.woff') format('woff'),
		url('#{$base-font-path}Polo22Klett-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Polo22Klett';
	font-weight: normal;
	font-style: italic;
	src: local(Polo22Klett),
		url('#{$base-font-path}Polo22Klett-Italic.woff2') format('woff2'),
		url('#{$base-font-path}Polo22Klett-Italic.woff') format('woff'),
		url('#{$base-font-path}Polo22Klett-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Polo22Klett';
	font-weight: bold;
	font-style: normal;
	src: local(Polo22Klett),
		url('#{$base-font-path}Polo22Klett-Bold.woff2') format('woff2'),
		url('#{$base-font-path}Polo22Klett-Bold.woff') format('woff'),
		url('#{$base-font-path}Polo22Klett-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Polo22Klett';
	font-weight: bold;
	font-style: italic;
	src: local(Polo22Klett),
		url('#{$base-font-path}Polo22Klett-BoldItalic.woff2') format('woff2'),
		url('#{$base-font-path}Polo22Klett-BoldItalic.woff') format('woff'),
		url('#{$base-font-path}Polo22Klett-BoldItalic.ttf') format('truetype');
}
