@import './klett_variables';
@import './klett_fonts';

* {
	box-sizing: border-box;

	&:focus {
		outline: none;
	}
}

body {
	margin: 0;
	font-family: $base-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #070712;
	font-size: 16px;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

button, table, ul, ol {
	font-family: $base-font-family;
}
