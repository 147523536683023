canvas,
.canvas > div {
	z-index: 1;
}

canvas {
  &.on-hover {
    cursor: pointer;
  }
}

.loadmsg {
	padding: 10px;
	font-size: 18px;
	// transform: translate3d(-50%, -50%, 0);
	color: #111;
	width: 6em;
	//margin: 4em auto 0;
}
