
$klett-red: #FF0000;
$klett-red-dark: #990000;
$klett-orange: #FF6600;
$klett-orange-2: #F29500;
$klett-neutral-grey-1: #F1F1F1;
$klett-neutral-grey-2: #E4E4E4;
$klett-neutral-grey-3: #D2D3D4;
$klett-neutral-grey-4: #A6A8AA;
$klett-neutral-grey-5: #949494;
$klett-warm-grey-1: #F5F3EC;
$klett-warm-grey-2: #E9E7D9;
$klett-warm-grey-3: #B2B0A1;
$klett-warm-grey-4: #928F74;

$klett-cool-blue: #00628B;

$color-copy-text: #383838;
$color-title-grey: #606060;
$color-link: $klett-red;
$color-link-visited: $klett-red-dark;
$color-table-head-bg: #E9E9E1;
$color-table-cell-bg: #F9F4F1;

$media-controller-bg: #f8f8f8;


$font-size-base: 16px;
$font-size-reference: .9375rem; // 15px
$font-size-title-1: 1.25rem; // 20px
$font-size-title-2: 1.125rem; // 18px
$font-size-subtitle: 1.125rem; // 18px
$font-size-copy: 1.125rem; // 18px
$font-size-small: 1rem; // 16px
$font-size-large: 1.25rem; // 20px
$line-height-base: 1.6;
$line-height-headline: 1.15;

$icon-size: 24px;
$ui-btn-size: 38px;


// spectre.css overrides

$primary-color: $klett-orange;
$secondary-color: $klett-red;
$dark-color: $color-copy-text;

$base-font-path: './fonts/';
$base-font-family: 'Polo22Klett', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !default;

$html-font-size: $font-size-base !default;
$html-line-height: $line-height-base !default;
$font-size: $font-size-copy !default;
$font-size-sm: $font-size-small !default;
$font-size-lg: 1.125em; // 18px
$line-height: $line-height-base !default;

// Modal

$modal-backdrop-color: rgba(0, 0, 0, .4);
$modal-background-color: #fff;

// spectre overrides

$body-font-family: $base-font-family !default;

$html-font-size: 20px !default;
$html-line-height: $line-height-base !default;
$font-size: 1rem !default;
$font-size-sm: .8rem !default;
$font-size-lg: 1.1rem !default;
$line-height: $line-height-base !default;
$body-font-color: $color-copy-text !default;
