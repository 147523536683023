@import '../../klett_variables';

.plyr {
	--plyr-audio-controls-background: #e4e4e4; //$klett-neutral-grey-2;
	--plyr-video-controls-background: #e4e4e4; //$klett-neutral-grey-2;
	--plyr-audio-control-color: #4a5464;
	--plyr-color-main: #ff6600; //$klett-orange;
	--plyr-control-radius: 0;
	--plyr-font-size-time: 14px;
	--plyr-range-thumb-background: #4a5464;
	--plyr-audio-progress-buffered-background: rgba(173,180,189,.6);
	--plyr-tooltip-color: #383838;
}
